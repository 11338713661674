import {get,post} from "../xhr"

export const clockIn = (requestData: {
    salary: number,
    type: 1|2,
    token: string,
    force?: boolean
}) => {
    return post('time_clock/web/clock_in', requestData)
}

export const validateToken = (token: string) => {
    return get('time_clock/web/validate/' + token);
}

export const fetchSalary = (token: string, id: number) => {
    return post('time_clock/web/fetch', {token: token, salary: id});
}