import Axios, {ResponseType} from "axios";

export const API_PROD_URL = 'https://api.sivan.io/api/';
export const API_DEV_URL = 'http://127.0.0.1:8000/api/';

const instance = () => {
    return Axios.create({
        baseURL: API_PROD_URL,
        headers : {
            'Content-Type': 'application/json',
        },
        timeout: 60000,
        transformResponse: [function (data) {
            return isJson(data) ? JSON.parse(data) : data
        }],
    })
}

function isJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function get(url: string){
    const api = instance();
    return api.get(url);
}

export function post(url: string, requestData: object){
    const api = instance();
    return api.post(url, requestData);
}