import * as React from "react";
import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import * as api from "../../adapters/timeclock";
import * as bootstrap from "bootstrap";

const TimeClock: React.FC = () => {

    const [type, setType] = useState<1|2|null>(null);
    const [salary, setSalary] = useState<{id: number, title: string}>();
    const [query, setQuery] = useState<string>();
    const params = useParams<{token?: string}>();
    const [token, setToken] = useState<string|undefined>(params.token);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [isTokenLoading, setIsTokenLoading] = useState<boolean>(!!token);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [clockInLoading, setClockInLoading] = useState<boolean>(false);
    const [clockInValidated, setClockInValidated] = useState<boolean>(false);
    const [anomaly, setAnomaly] = useState<boolean>(false);
    const [anomalyType, setAnomalyType] = useState<1|2>();
    let fn: NodeJS.Timeout;

    useEffect(() => {
        if (token){
            setIsTokenLoading(true);
            api.validateToken(token)
                .then(data => {
                    setIsTokenLoading(false)
                    setIsTokenValid(true)
                }).catch(data => {
                setIsTokenLoading(false)
                setIsTokenValid(false)
            })
        }
    }, [])


    useEffect(() => {
        if (!type && salary){
            setSalary(undefined)
        }
    }, [type])

    const keyPress = (query: string) => {
        clearTimeout(fn);
        if (query){
            fn = setTimeout(() => {
                if (isTokenValid && query){
                    setIsLoading(true)
                    api.fetchSalary(token!, Number(query))
                        .then(data => {
                            setSalary(data.data);
                            setIsLoading(false)
                        })
                        .catch(error => {
                            setSalary(undefined);
                            setIsLoading(false)
                        })
                }
            }, 1000);
        }else{
            setSalary(undefined)
        }
    }

    const validate = (force?: boolean) => {
        if (salary && type && token){
            setClockInLoading(true)
            api.clockIn({
                salary: salary.id,
                type: type,
                token: token,
                force: force
            }).then(data => {
                setClockInValidated(true)
                setClockInLoading(false)
                setType(null)
                setSalary(undefined)
                setAnomaly(data.data.anomalie)

                setTimeout(() => {
                    setClockInValidated(false)
                    setAnomaly(false)
                }, 2000);

            }).catch(error => {
                if (error.response.data.isAnomaly){
                    setClockInLoading(false);
                    openModal(error.response.data.anomaly)
                }
            })
        }
    }

    const [modal, setModal] = useState<bootstrap.Modal>()
    const openModal = (anomaly: 1|2) => {
        setAnomalyType(anomaly)
        let myModal = new bootstrap.Modal(document.getElementById('anomalyModal')!)
        setModal(myModal)
        myModal.show();
    }

    const closeModal = () => {
        if (modal){
            modal.hide();
        }
    }

    if (clockInValidated)
    {
        return  <div className="container mx-auto">
            <div className="card shadow-sm p-1 p-md-3">
                <h4>
                    <i className={'bi bi-check2-circle text-success'}> </i> Votre badgeage a bien été pris en compte
                </h4>
                {anomaly && <div className="alert alert-warning">
                    <i className={"bi bi-exclamation-circle"}> </i> Anomalie de badgeage
                </div>}
            </div>
        </div>
    }

    if (isTokenLoading){
        return  <div className="container my-auto mx-auto">
            <div className="w-100 p-1 p-md-3 d-flex">
                <span className="spinner-border text-primary mx-auto" role="status">
                    <span className="visually-hidden">Loading...</span>
                </span>
            </div>
        </div>
    }

    if (isTokenValid){
        return <>
            <div className="container mb-3 mx-auto flex-grow-1 d-flex flex-column">
                <div className="card shadow-sm p-1 p-md-3">
                    <div className="w-100 d-flex mb-3">
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div className={' step me-2 shadow-sm flex-grow-0 ' + (type ? ' bg-primary text-white' : ' bg-light')}>
                              {type ? <i className={"bi bi-check"}> </i> : "1"}
                          </div>
                            <div className="text-start flex-grow-1">
                                Séléctionner une entrée ou une sortie
                            </div>
                        </div>
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div className={'step me-2 shadow-sm flex-grow-0 ' + (type && salary ? ' bg-primary text-white' : ' bg-light')}>
                              {type && salary ? <i className={"bi bi-check"}> </i> : "2"}
                          </div>
                            <div className={"text-start flex-grow-1"}>
                                Saisissez votre identifiant de badgeuse
                            </div>
                        </div>
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div className={' step me-2 shadow-sm flex-grow-0 ' + (clockInLoading ? ' bg-primary text-white' : ' bg-light')}>
                              {clockInLoading ? <i className={"bi bi-check"}> </i> : "3"}
                          </div>
                            <div className="text-start flex-grow-1">
                                Validez !
                            </div>
                        </div>
                    </div>
                    {!type && <div className="w-100 mb-3 text-center">
                        {!type && <span className="h4 text-primary">
                            Veuillez séléctionner une entrée ou une sortie
                        </span>}
                    </div>}
                    <div className="d-flex mb-3">
                        <div className="flex-grow-1">
                            <button
                                disabled={clockInLoading}
                                onClick={() => setType(prevState => prevState === 1 ? null : 1)}
                                className={'btn btn-outline-success w-100 text-uppercase ' + (type === 1 ? ' active' : '')}>
                                Entrée
                            </button>
                        </div>
                        <div className="flex-grow-0 mx-2">

                        </div>
                        <div className="flex-grow-1">
                            <button
                                disabled={clockInLoading}
                                onClick={() => setType(prevState => prevState === 2 ? null : 2)}
                                className={'btn btn-outline-danger w-100 text-uppercase ' + (type === 2 ? ' active' : '')}>
                                Sortie
                            </button>
                        </div>
                    </div>
                    {type && <>
                        <div className="w-100 mb-3">
                            {!salary && <h4 className={"text-primary"}>
                                Saisissez votre code pour vous identifier
                            </h4>}
                            <div className="input-group">
                                <input
                                    defaultValue={salary?.id}
                                    disabled={isLoading || clockInLoading}
                                    onChange={(e) => keyPress(e.target.value)}
                                    type="number"
                                    className={'form-control'}
                                />
                            </div>
                            <div className="w-100 my-3 text-center">
                                {isLoading ? <>
                                <span className="spinner-border text-primary me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </span>
                                    <span className={'fs-1'}>
                                        Vérification de l'identifiant
                                    </span>

                                </>  :
                                    <span className={'fs-1'}>
                                        {salary ? <><i className={"bi bi-check-circle text-success"}> </i> {salary.title}</> : <>
                                            <i className={'bi bi-search'}> </i> Aucun résultat</>}
                                    </span>}
                            </div>
                        </div>
                        {salary && !isLoading && <>
                            <button disabled={clockInLoading} onClick={() => validate()} className={'btn btn-lg btn-outline-success w-100'}>
                                {clockInLoading ?
                                    <div className="spinner-border text-success" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    : <><i className={'bi bi-check2-circle'}> </i> Valider</>}
                            </button>
                        </>}
                    </>}
                </div>
            </div>


            <div id={"anomalyModal"} className="modal" tabIndex={-1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <i className={'bi bi-exclamation-circle'}> </i>
                                Anomalie
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"> </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 mb-3">
                                <p className={'lead'}>
                                    {anomalyType === 1 && <>
                                        Le dernier badgeage était une entrée et vous tentez de rebadger une entrée.
                                    </>}
                                    {anomalyType === 2 && <>
                                        Le dernier badgeage était une sortie et vous tentez de rebadger une sortie.
                                    </>}
                                </p>
                                <p className={'lead'}>
                                    Souhaiter vous valider ou annuler ce badgeage ?
                                </p>
                            </div>
                            <div className="d-flex w-100">
                                <div className="flex-grow-1">
                                    <button onClick={() => closeModal()}
                                             className={'btn btn-danger w-100'}>
                                        Annuler
                                    </button>
                                </div>
                                <div className="flex-grow-0 mx-2"> </div>
                                <div className="flex-grow-1">
                                    <button onClick={() => {
                                        closeModal()
                                        validate(true)
                                    }} className={'btn btn-success w-100'}>
                                        Valider quand même
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    return  <div className="container my-auto mx-auto">
        <div className="card shadow-sm p-1 p-md-3">
                <span className="fs-3">
                    <i className={'bi bi-exclamation-triangle text-danger'}> </i> Lien non valide
                </span>
        </div>
    </div>
}

export default TimeClock;