import React, {useEffect, useState} from 'react';
import './App.css';
import TimeClock from "./component/timeclock";
import {
    HashRouter,
    Routes,
    Route,
} from "react-router-dom";
import { format } from "date-fns";

function App() {
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        setTimeout(() => {
            setNow(new Date());
        }, 1000)
    }, [now])

  return (
      <div className="App d-flex flex-column">
          <div className="container mx-auto my-1 my-md-3 d-flex">
              <div className="flex-grow-1">
                  <img className={'mx-auto logo'} src={process.env.PUBLIC_URL + "/logo/LOGO_SIVAN_BLEU.svg"} alt=""/>
              </div>
              <div className="flex-grow-1 text-end">
                  <div className="timer">
                      {format(now, 'HH:mm:ss')}
                  </div>
              </div>
          </div>
          <HashRouter>
              <Routes>
                  <Route path="/" element={<TimeClock />}>
                      <Route path=":token" element={<TimeClock />} />
                  </Route>
              </Routes>
          </HashRouter>
    </div>
  );
}

export default App;
